'use client';

import { Client } from '@/app/lib/client/client';
import type { ServerSocketEmit, ThreadWithInfo } from '@/app/lib/dtos/ServerSocketEmit';
import type { ThreadId } from '@/app/lib/entities/Thread.entity';
import type { User } from '@/app/lib/entities/User.entity';
import { Text } from '@/app/lib/helpers/Text';
import { socket } from '@/app/socket';
import { SocketContext, type SocketData } from '@/app/socket-context';
import { type ReactNode, useEffect, useState } from 'react';

export interface Props {
	children: ReactNode;
}

export default function SocketProvider(props: Props) {
	const storedUserId = Client.Authenticate();

	const [user, setUser] = useState<User | undefined>();
	const [threads, setThreads] = useState<ThreadWithInfo[]>([]);
	const [isConnected, setIsConnected] = useState<boolean>(false);
	const selectedThread = (threads ?? []).find((t) => t.id == user?.selectedThreadId);
	const projectId = selectedThread?.projectId;

	const isThreadAlreadySelected = (threadId: ThreadId | undefined) =>
		window.location.pathname.startsWith(`/${threadId ?? ''}`);

	// eslint-disable-next-line
	const selectThreadId = (threadId: ThreadId | undefined, user: User | undefined) => {
		if (!threadId || !user) {
			return;
		}

		const cancellationToken = Text.RandomString();
		Client.Emit({ type: 'subscribe', threadId, cancellationToken, userId: user.id });
		Client.Emit({ type: 'thread-selected', threadId, cancellationToken: Text.RandomString(), userId: user.id });

		if (!isThreadAlreadySelected(threadId)) {
			globalThis.location.pathname = `/${threadId}`;
		}
	};

	// eslint-disable-next-line
	const userSubscriptionListener = (m: ServerSocketEmit) => {
		if (m.type == 'threads') {
			setThreads(m.body);
		} else if (m.type == 'user') {
			setUser(m.body);
			if (!isThreadAlreadySelected(m.body.selectedThreadId)) {
				selectThreadId(m.body.selectedThreadId, m.body);
			}
		} else if (m.type == 'bootstrap') {
			setUser(m.user);
			setThreads(m.threads);
		} else if (m.type == 'force-navigate-to-thread') {
			globalThis.location.pathname = `/${m.threadId}`;
		}
	};

	useEffect(() => {
		let timeout: NodeJS.Timeout | undefined = undefined;

		const onConnect = () => {
			setIsConnected(true);
			timeout = setTimeout(() => {
				if (storedUserId) {
					Client.Emit({ type: 'bootstrap', userId: storedUserId, cancellationToken: Text.RandomString() });
				}
			});
		};

		const onDisconnect = () => {
			setIsConnected(false);
			if (timeout) {
				clearTimeout(timeout);
			}
		};

		if (socket.connected) {
			onConnect();
		}
		socket.on('connect', onConnect);
		socket.on('disconnect', onDisconnect);
		if (storedUserId) {
			socket.off(storedUserId, userSubscriptionListener);
			socket.on(storedUserId, userSubscriptionListener);
		}

		return () => {
			socket.off('connect', onConnect);
			socket.off('disconnect', onDisconnect);
			socket.off(storedUserId, userSubscriptionListener);
			if (timeout) {
				clearTimeout(timeout);
			}
		};
		// ensure no dependencies
		// eslint-disable-next-line
	}, [storedUserId]);

	useEffect(() => {
		let cancellationToken: string | undefined = undefined;
		if (isConnected && projectId && user) {
			cancellationToken = Text.RandomString();
			Client.Emit({ type: 'get-builds', projectId, cancellationToken: Text.RandomString(), userId: user.id });
		}
		return () => {
			if (cancellationToken && user && user?.selectedThreadId) {
				Client.Emit({ type: 'cancel', threadId: user.selectedThreadId, cancellationToken, userId: user.id });
			}
		};
	}, [isConnected, user, projectId]);

	const socketData: SocketData = {
		isConnected,
		user,
		threads: threads ?? [],
		selectThreadId,
	};

	return <SocketContext.Provider value={socketData}>{props.children}</SocketContext.Provider>;
}
